import React, { Component } from "react"

import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import Login from "./auth/Login";
import Main from "./main/Main";

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: false
    }

    this.handleLogin = this.handleLogin.bind(this)
  }

  handleLogin() {
    this.setState({
      loggedIn: true
    });
  }

  PrivateMainRoute = ({...rest}) => {
    return (
      <Route
        {...rest}
        render={(props) => this.state.loggedIn === true 
          ? <Route exact path={"/main"} r render={props => (<Main { ... props} handleLogin={this.handleLogin} loggedIn={this.state.loggedIn}/>)}/>
          : <Redirect to={{pathname: "/login", state: {from: props.location}}} />
        }
      />
    )
  }

  render() {
    return (
      <div className="app">
        <Router>
          <Switch>
            <Route
              exact
              path="/"
              render={() => {
                  return (
                      this.state.loggedIn ?
                      <Redirect to="/main" /> :
                      <Redirect to="/login" /> 
                  )
              }}
            />
            <this.PrivateMainRoute exact path={"/main"}/>
            <Route exact path={"/login"} render={props => (<Login { ... props} handleLogin={this.handleLogin} loggedIn={this.state.loggedIn}/>)}/>
          </Switch>
        </Router>
      </div>
    );
  }
}