import logo from "../../static/logo.svg";
import "./Main.css";
import React, { Component } from "react";
import axios from "axios"
import Loader from "react-loader-spinner";

import Cookies from 'js-cookie';

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export default class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      caseId: "",
      lender: "ucfs",
      caseData: {},
      loaderVisible: false,
      submitDisabled: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.httpRequest = this.httpRequest.bind(this);
    this.validateNumericalInput = this.validateNumericalInput.bind(this);
    this.resetInput = this.resetInput.bind(this);
    this.validateCaseData = this.validateCaseData.bind(this);
    this.submitFinancing = this.submitFinancing.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.toggleSubmit = this.toggleSubmit.bind(this);
    this.toggleLoader = this.toggleLoader.bind(this);
    this.notify = this.notify.bind(this);
  }


  handleChange(e) {
    this.setState({
      [e.target.id]: e.target.value
    });
  }

  validateNumericalInput(e) {
    if (/^\d+$/.test(e.target.value) || e.target.value === "") {
      this.handleChange(e)
    }
  }

  resetInput(e) {
    document.getElementById("caseId").style.color = "black"
    document.getElementById("caseId").style.borderBottomColor = "#63aef4"
    document.getElementById("caseId-label").style.color = "#64ace2"
  }

  async httpRequest(method, url, payload = "") {
    axios.defaults.withCredentials = true;
    try {
      let config = {
        url: url,
        method: method,
      }
      if (payload) {
        config.headers = { "Content-Type": "application/json; charset=UTF-8" }
        config.data = payload
      }
      return await axios(config)
    }
    catch (e) {
      return e.response
    }
  }

  notify(type, message) {
    if (type === "success") {
      toast.info(message);
    }
    else if (type === "error") {
      toast.error(message);
    }
  }

  validateCaseData() {
    let data = this.state.caseData
    let lender = this.state.lender
    if (lender === "fmc") {
      data = { "Email": data["Email"], "SSN": data["SSN"], "Amount Requested": data["Amount Requested"] }
    }
    for (let k in data) {
      if (!data[k] || data[k] === "") {
        return false
      }
    }
    return true
  }

  toggleSubmit() {
    this.setState({
      submitDisabled: !this.state.submitDisabled
    })
  }

  toggleLoader() {
    this.setState({
      loaderVisible: !this.state.loaderVisible
    })
  }

  submitFinancing() {
    if (this.state.submitDisabled) {
      return
    }

    if (!this.validateCaseData()) {
      this.notify("error", `Case ${this.state.caseId} missing required fields`);
      return
    }

    document.getElementById("button-submit").style.backgroundColor = "grey"
    this.toggleSubmit()
    this.toggleLoader()

    this.httpRequest("post", `${process.env.REACT_APP_SERVER_URL}/api/submit/logics/${this.state.lender}`, { CaseID: this.state.caseId }).then((res) => {
      if (res.status === 200) {
        this.toggleLoader()
        this.closeModal()
        this.toggleSubmit()
        this.notify("success", `${this.state.lender.toUpperCase()} Financing Submitted For $${this.state.caseData["Amount Requested"]}`);
      }
      else if (res.status === 401 || res.status === 403) {
        this.props.history.push("/login")
      }
      else {
        this.toggleLoader()
        this.closeModal()
        this.toggleSubmit()
        this.notify("error", `${this.state.lender.toUpperCase()} Financing Error` +
          (res.data.message === "" ? "" : `: ${res.data.message}`));
      }
    })
  }

  closeAlert() {
    this.setState({
      show: false
    })
  }

  openModal = () => {
    if (this.state.caseId && this.state.lender) {
      this.httpRequest("get", `${process.env.REACT_APP_SERVER_URL}/api/case/logics?case_id=${this.state.caseId}`).then((res) => {
        if (res.status === 200) {
          let data = res.data["data"]
          let amount = data["Amount Requested"]
          switch (this.state.lender) {
            case "fmc":
              data["Amount Requested"] = amount > 30000 ? 30000 : amount;
              break;
            case "ucfs":
              data["Amount Requested"] = amount > 13000 ? 13000 : amount;
              break;
            case "hac":
              data["Amount Requested"] = amount > 15000 ? 15000 : amount;
              break;
            case "sfc":
              data["Amount Requested"] = amount > 7000 ? 7000 : amount;
              break;
            case "eqty":
              data["Amount Requested"] = amount > 20000 ? 20000 : amount;
              break;
            case "ucfs_hac":
              data["Amount Requested"] = amount > 13000 ? 13000 : amount;
              break;
            default:
              break;
          }
          this.setState({
            caseData: {
              "CaseID": data.CaseID,
              "First Name": data.FirstName,
              "Last Name": data.LastName,
              "SSN": data.SSN,
              "Date of Birth": data.DOB,
              "Email": data.Email,
              "Mobile #": data.CellPhone,
              "Street": data.Address,
              "City": data.City,
              "State": data.State,
              "Zip Code": data.Zip,

              "Amount Requested": data["Amount Requested"],
              "Term": data.Term,

              "Residence": data.Residence,
              "Mortage or Rent Payment": data["Mortage or Rent Payment"],
              "Move in Date": data["Move in Date"],

              "Employment Start Date": data["Employment Start Date"],
              "Employment Type": data["Employment Type"],
              "Monthly Gross Income": data["Monthly Gross Income"]
            }
          });
          document.getElementById("modal").style.display = "block"
        }
        else if (res.status === 401 || res.status === 403) {
          this.props.history.push("/login")
        }
        else {
          document.getElementById("caseId").style.color = "#ff2424"
          document.getElementById("caseId").style.borderBottomColor = "#ff2424"
          document.getElementById("caseId-label").style.color = "#ff2424"
        }
      })
    }
    else {
      document.getElementById("caseId").style.color = "#ff2424"
      document.getElementById("caseId").style.borderBottomColor = "#ff2424"
      document.getElementById("caseId-label").style.color = "#ff2424"
    }
  }

  closeModal() {
    document.getElementById("modal").style.display = "none"
    document.getElementById("button-submit").style.backgroundColor = "#028fed"
    this.setState({
      loaderVisible: false
    });
  }

  render() {
    return (
      <div id="main">
        <ToastContainer />
        <div className="Logo">
          <img src={logo} className="Main-logo" alt="logo" />
        </div>

        <div className="Main" id="form">
          <div class="form__group field">
            <input type="text" class="form__field" placeholder="Case Number" id="caseId" onClick={this.resetInput} value={this.state.caseId} onChange={this.validateNumericalInput} required />
            <label class="form__label" id="caseId-label" >Case Number</label>
          </div>

          <br></br>
          <br></br>
          <div class="form__group field">
            <select id="lender" class="form__field" value={this.state.lender} onChange={this.handleChange} required>
            <option value="eqty">EQTY</option>
              <option value="ucfs">UCFS</option>
              <option value="hac">HAC</option>
              <option value="fmc">FMC</option>
              <option value="sfc">SFC</option>
              <option value="ucfs_hac">UCFS + HAC</option>
            </select>
            <label class="form__label">Lender</label>
          </div>
          <br></br>
          <br></br>
          <div className="button__group">
            <button id="button-finance" className="button" onClick={this.openModal}>Finance</button>
          </div>
          <div id="modal" className="modal">
            <div className="modal-container">
              <div className="header-container">
                <button class="buttonClass"></button>
                <h1 className="header">Finance Applicant</h1>
                <button class="buttonClass" onClick={this.closeModal}>&times;</button>
              </div>
              <div className="flex-container">
                <div className="item">
                  {
                    Object.keys(this.state.caseData).map((key, i) => (
                      <p><strong>{key}</strong></p>
                    ))
                  }
                </div>
                <div className="item">
                  {
                    Object.keys(this.state.caseData).map((key, i) => (
                      (this.state.caseData[key])
                        ? <p>{this.state.caseData[key]}</p>
                        : <p>&nbsp;</p>
                    ))
                  }
                </div>
              </div>
              <div id="submit-container" className="submit-container">
                {
                  (this.state.lender !== "fmc")
                    ? [
                      <label id="warning" className="warning">*All fields above are required</label>,
                      <br></br>,
                      <br></br>
                    ]
                    : [
                      <label id="warning" className="warning">*Email, SSN and Amount Requested are required</label>,
                      <br></br>,
                      <br></br>
                    ]
                }
                <a className="button" href={`http://34.94.31.31/financing_app/update_financing_request/?co_barr=alsdqwoeidnqweu93bqdh8&caseid=${this.state.caseId}&ck=${Cookies.get('TAXRISE_FP_JWT')}`}>&nbsp;Edit Before Submit&nbsp;</a>
                <br />
                <br />
                <br />
                <div className="button-loader">
                  <button id="button-submit" className="button" onClick={this.submitFinancing}>Submit</button>
                  <Loader id="loader" className="loader" type="TailSpin" color="#028fed" height={35} width={35} visible={this.state.loaderVisible} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
