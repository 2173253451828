import logo from "../../static/logo.svg";
import { Component } from "react";
import "./Login.css";
import axios from "axios"

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      key: ""
    };

    this.login = this.login.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.authorize = this.authorize.bind(this);
  }

  async authorize() {
    try {
      const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/authorize`, { withCredentials: true })
      return res.data["data"]["authorized"]
    }
    catch (error) {
    }
  }

  componentDidMount() {
    this.authorize().then((authorized) => {
      if (authorized) {
        this.props.handleLogin()
        this.props.history.push("/main")
      }
    })
  }

  handleChange(e) {
    this.setState({
      [e.target.id]: e.target.value
    });
  }

  resetInput(e) {
    document.getElementById("key").style.color = "black"
    document.getElementById("key").style.borderBottomColor = "#63aef4"
    document.getElementById("key-label").style.color = "#64ace2"
  }

  login(e) {
    if (this.state.key !== "") {
      axios.defaults.withCredentials = true;
      axios.post(`${process.env.REACT_APP_SERVER_URL}/api/login`, { key: this.state.key }, {
        headers: {
          "Content-type": "application/json",
        }
      }).then(res => {
        if (res.status === 200) {
          this.props.handleLogin();
          this.props.history.push("/main")
        }
        else {
          throw new Error()
        }
      })
        .catch(rejected => {
          document.getElementById("key").style.color = "#ff2424"
          document.getElementById("key").style.borderBottomColor = "#ff2424"
          document.getElementById("key-label").style.color = "#ff2424"
        })
    }
    else {
      document.getElementById("key").style.color = "#ff2424"
      document.getElementById("key").style.borderBottomColor = "#ff2424"
      document.getElementById("key-label").style.color = "#ff2424"
    }
  }

  render() {
    return (
      <div id="main">
        <div className="Logo">
          <img src={logo} className="Main-logo" alt="logo" />
        </div>
        <div className="Main">
          <div class="form__group field">
            <input type="password" id="key" class="form__field" placeholder="Key" value={this.state.key} onClick={this.resetInput} onChange={this.handleChange} required />
            <label id="key-label" class="form__label">Key</label>
          </div>
          <br></br>
          <br></br>
          <div className="button__group">
            <button type="submit" className="button" onClick={this.login}>Login</button>
          </div>
        </div>
      </div>
    );
  }
}
